import React from 'react';

export const Footer = ({ contact, impressum }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isImpressumModalOpen, setImpressumModalOpen] = React.useState(false);

  const toggleDatenschutzModal = () => {
    setModalOpen((prev) => !prev);
  };
  const toggleImpressumModal = () => {
    setImpressumModalOpen((prev) => !prev);
  };

  return (
    <div id="footer">
      <div className="container">
        <div className="footer-grid">
          <div className="footer-column" style={{ alignItems: 'center' }}>
            <img src="img/logo200px.png" alt="logo" width={100} />
            <div className="footer-brand-large">KOPF</div>
            <div className="footer-brand-small">Managementberatung</div>
          </div>
          <div className="footer-column">
            <p>{contact ? contact.address : 'Loading'}</p>
            <p>{contact ? contact.phone : 'Loading'}</p>
            <p>{contact ? contact.email : 'Loading'}</p>
          </div>
          <div className="footer-column">
            <a href="#header" className="page-scroll">
              Home
            </a>
            <a href="#features" className="page-scroll">
              Leistungen
            </a>
            <a href="#about" className="page-scroll">
              Über uns
            </a>
            <a href="#contact" className="page-scroll">
              Kontakt
            </a>
          </div>
        </div>
        <div className="footer-divider"></div>
        <div
          className="footer-grid"
          style={{
            alignItems: 'center',
            marginBottom: '-65px',
          }}
        >
          <div className="footer-column">
            <a
              style={{ fontSize: '14px' }}
              className=""
              href="#datenschutz"
              onClick={toggleDatenschutzModal}
            >
              Datenschutzerklärung
            </a>
            <a
              style={{ fontSize: '14px', marginTop: '-5px' }}
              className=""
              href="#impressum"
              onClick={toggleImpressumModal}
            >
              Impressum
            </a>
          </div>
          <div className="footer-column">
            <p style={{ fontSize: '14px' }}>Website entwicklung:</p>
            <p style={{ fontSize: '14px', marginTop: '-10px' }}>
              moregoritt@gmail.com
            </p>
          </div>
          <p style={{ fontSize: '14px' }}>
            {contact ? contact.copyright : 'Loading'}
          </p>
        </div>
      </div>
      {isModalOpen && (
        <div
          className="modal"
          onClick={(e) => {
            if (e.target.classList.contains('modal')) {
              toggleDatenschutzModal();
            }
          }}
        >
          <div className="modal-content">
            <span className="close" onClick={toggleDatenschutzModal}>
              &times;
            </span>
            <iframe
              src="/Datenschutz.pdf#toolbar=0&navpanes=0&scrollbar=0&zoom=120"
              width="100%"
              height="80%"
              title="Datenschutz PDF"
            />
          </div>
        </div>
      )}
      {isImpressumModalOpen && (
        <div
          className="modal-impressum"
          onClick={(e) => {
            if (e.target.classList.contains('modal-impressum')) {
              toggleImpressumModal();
            }
          }}
        >
          <div className="modal-impressum-content">
            <span className="close" onClick={toggleImpressumModal}>
              &times;
            </span>
            <h4 style={{ marginBottom: '20px' }}>Impressum</h4>
            <p style={{ color: 'black', marginBottom: '10px' }}>
              {impressum ? impressum.name : 'Loading'}
            </p>
            <p style={{ color: 'black' }}>
              {impressum ? impressum.address : 'Loading'}
            </p>
            <p style={{ color: 'black', marginBottom: '10px' }}>
              {impressum ? impressum.postalCode : 'Loading'}
            </p>
            <p style={{ color: 'black' }}>
              {impressum ? impressum.phone : 'Loading'}
            </p>
            <p style={{ color: 'black' }}>
              {impressum ? impressum.email : 'Loading'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
